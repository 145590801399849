import { isNumeric, isObject } from 'rxjs/internal-compatibility';
import { isString } from 'micro-dash';

interface FilterComparator {
  [key: string]: (columnValue: any, filterValue: any) => boolean;
}

export class FilterManager<TModel> {
  protected filterColumns: string[];

  constructor(protected filters: any, protected comparators: FilterComparator = null) {
    this.filterColumns = Object.keys(this.filters);
  }

  run(list: TModel[]): TModel[] {
    if (list.length === 0) {
      return [];
    }

    return list.filter((row) => {
      let accepted: boolean = true;
      for (let i = 0; i < this.filterColumns.length; i++) {
        const columnKey = this.filterColumns[i];
        const columnValue = row[columnKey];
        const filterValue = this.filters[columnKey];

        if (filterValue) {
          if (this.comparators && this.comparators[columnKey]) {
            accepted = accepted && this.comparators[columnKey](columnValue, filterValue);
          } else {
            accepted = accepted && this.isEqual(columnValue, filterValue);
          }
        }

        if (!accepted) {
          break;
        }
      }
      return accepted;
    });
  }

  protected isEqual(columnValue, filterValue): boolean {

    if ((isObject(columnValue) && isNumeric(columnValue.id)) &&
        (isObject(columnValue) && isNumeric(filterValue.id))) {
      return columnValue.id === filterValue.id;
    }

    if (isString(columnValue) && isString(filterValue)) {
      const query = filterValue.toLowerCase();
      return columnValue.toString().toLowerCase().indexOf(query) > -1;
    }

    return columnValue === filterValue;
  }
}
